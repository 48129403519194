<template>
  <b-card>
    <form-component @onSubmit="onSubmit" :posts="posts" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/blog/posts/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { all, create } from '@/http/blog/posts';
import { createBlogPostForm } from '@/constants/utils/form';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },
  data() {
    return {
      posts: [],
    };
  },

  async created() {
    await this.fetchPosts();
  },

  methods: {
    async fetchPosts() {
      try {
        const { data } = await all();

        this.posts = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił problem z pobraniem postów. Skontaktuj się ze swoim developerem.',
        );
      }
    },
    async onSubmit(form) {
      const formData = createBlogPostForm(form);

      try {
        await create(formData);

        this.showSuccessNotification('Dane zostały zapisane', 'Post został dodany.');
        this.$router.push({ name: 'blog-posts-index' });
      } catch (error) {
        const message =
          error.response.data.message ?? 'Wystąpił problem z edycją kategorii. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>

<style lang="scss"></style>
